<template>
  <div class="source">
    <van-nav-bar
      :title="caption"
      left-arrow
      :right-text="isShowClose ? '关闭' : ''"
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div class="wrapper hidden-scrollbar">
      <div v-show="!isShowClose">
        <simpleTable
          :columns="titles"
          :datas="bodys"
          @onRow="showDetails"
        ></simpleTable>
      </div>
      <div v-show="isShowClose">
        <div
          v-for="(item, index) in chartsDatas"
          :key="index"
          class="p-b-10 cv"
        >
          <cv height="66.67vw" :datas="item"></cv>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store";
import { useRouter } from "vue-router";
import { NavBar } from "vant";
import cv from "@/components/chart/echartsTable.vue";
import simpleTable from "@/components/table/simpleTable.vue";
import { global_option, achievement_option } from "@/utils/echartsOption";
import { deepObjectMerges } from "@/utils/app";

export default defineComponent({
  components: { [NavBar.name]: NavBar, cv, simpleTable },
  setup() {
    const store: Store = useStore();
    const router = useRouter();
    const { userid, username, dept_id } = store.state.app;

    type RowData = {
      name: string;
      datas: (number | string)[];
      org_no: string;
    };

    const dataMap = reactive({
      caption: "区域来单数据",
      isShowClose: false,
      titles: ["区域"] as string[],
      originStatusYear: store.state.app.reportConfig?.originStatusYear,
      channelGroup: store.state.app.reportConfig?.channelGroup,
      bodys: [] as RowData[],
      chartsDatas: [] as object[],
    });

    const onClickLeft = () => {
      router.go(-1);
    };
    const onClickRight = () => {
      dataMap.caption = "区域来单数据";
      dataMap.isShowClose = false;
    };

    const getReportDataChannelList = () => {
      store
        .dispatch("actReportDataChannelList", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          year: dataMap.originStatusYear!,
          channel_group: dataMap.channelGroup!,
        })
        .then((res) => {
          if (res.status === 200) {
            for (let key in res.data_list.title_list) {
              dataMap.titles.push(res.data_list.title_list[key]);
            }
            res.data_list.list.forEach((el) => {
              let tem: RowData = {
                name: el.name,
                datas: [el.name],
                org_no: el.org_no,
              };
              for (let key in res.data_list.title_list) {
                tem.datas.push(el[key]);
              }
              dataMap.bodys.push(tem);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const showDetails = (item: RowData) => {
      store
        .dispatch("actReportDataChannelYearMon", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          channel_group: dataMap.channelGroup!,
          org_no: item.org_no,
        })
        .then((res) => {
          if (res.status === 200) {
            dataMap.chartsDatas = [];
            dataMap.caption = res.data_list.title;
            for (let key in res.data_list.list) {
              let option = JSON.parse(JSON.stringify(achievement_option));
              option.title = {
                text: key,
                x: "center",
                y: "5%",
              };
              option.legend = {
                data: res.data_list.year_list,
              };
              res.data_list.year_list.forEach((el, index) => {
                option.series[index].name = el;
                option.series[index].data = (
                  res.data_list.list[
                    key as keyof ReportDataChannelYearMonReq["list"]
                  ][el] as string
                ).split(",");
              });
              option.grid.top = "20%";
              dataMap.chartsDatas.push(
                deepObjectMerges([
                  JSON.parse(JSON.stringify(global_option)),
                  option,
                ])
              );
            }
            dataMap.isShowClose = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getReportDataChannelList();
    return {
      ...toRefs(dataMap),
      onClickLeft,
      onClickRight,
      showDetails,
    };
  },
});
</script>

<style lang="less" scoped>
.wrapper {
  overflow: auto;
  height: calc(100vh - 46px);
  .cv {
    border-bottom: 1px solid #eee;
  }
}
</style>